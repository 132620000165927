<template>
  <div>
    <v-form v-model="valid">
      <v-container fluid>
        <v-row align="center" justify="space-around">
          <v-text-field
            v-model="fullName"
            label="Name"
          ></v-text-field>
        </v-row>
        <v-row align="center" justify="space-around">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-row>
        <v-row align="center">
          <v-select
            v-model="campaign"
            :items="forms"
            :rules="[(v) => !!v || 'Campaign is required']"
            label="Campaign"
            item-text="formName"
            item-value="formId"
            return-object
            required
          ></v-select>
        </v-row>
        <v-spacer></v-spacer>
        <v-row align="center">
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click.prevent="createUser()"
            >Create</v-btn
          >
        </v-row>
      </v-container>
    </v-form>
    <v-alert text v-if="feedback">{{ feedback }}</v-alert>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Account</th>
            <th class="text-left">Campaigns</th>
            <th class="text-left">Name</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user._id">
            <td>{{ user.email }}</td>
            <td>{{ user.campaigns.map((c) => c.name).join(", ") }}</td>
            <td>
              {{user.fullName && user.fullName}}
            </td>
            <td>
              <router-link
                :to="{ name: 'editUser', params: { user: user._id } }"
              >
                <v-btn class="mx-1" rounded raised small color="primary">Edit</v-btn>
              </router-link>
              <v-btn
                class="mx-1"
                raised
                small
                color="error"
                rounded
                @click="deleteUser(user._id)"
                >Delete</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>


<script>
import axios from "axios";
import generator from "generate-password";

export default {
  data: () => {
    return {
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      campaign: null,
      forms: [],
      feedback: "",
      users: [],
      name: '',
      fullName: ''
    };
  },
  created() {
    axios
      .get("/api/form")
      .then((response) => {
        this.forms = response.data.reverse();
        this.sortByKey(this.forms, this.forms.submittedAt);
      })
      .catch((error) => {
        this.feedback = error;
      });
    this.initialize();
  },
  methods: {
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    async createUser() {
      try {
        const user = {
          email: this.email,
          password: generator.generate({ length: 10, numbers: true }),
          campaigns: [],
          fullName: this.fullName
        };
        user.campaigns.push({
          id: this.campaign.formId,
          name: this.campaign.formName,
        });
        await axios.post("/api/admin/create", user);
        this.sendMail(user.password);
        this.feedback = "New user posted";
        this.email = "";
        this.campaign = null;
        this.users.push(user);
      } catch (error) {
        this.feedback = error;
      }
    },
    async sendMail(password) {
      try {
        const mailOptions = {
          to: this.email,
          text: `Someone has shared access to Your Level up recruitment campaign.<br> Your account details are: <br> Username: ${this.email} <br> Password: ${password} <br> Use this link to login: https://app.levelup.ee/`,
          subject: "Level up kampaania parool",
          html: `<br>Someone has shared access to Your Level up recruitment campaign.<br><br> Your account details are: <br>👤 Username: ${this.email} <br>🗝️ Password: ${password} <br>🔗 Use this link to login: https://app.levelup.ee/`,
        };
        await axios.post("/api/user/mail", mailOptions);
        this.feedback = "Mail sent!";
      } catch (error) {
        this.feedback = error;
      }
    },
    async initialize() {
      try {
        const { data } = await axios.get("/api/admin/users");
        this.users = data.users;
      } catch (error) {
        this.feedback = error;
      }
    },
    async deleteUser(id) {
      try {
        if (confirm("Are you sure?")) {
          await axios.delete(`/api/admin/${id}`);
          this.users = this.users.filter((o) => o._id !== id);
        }
      } catch (error) {
        this.feedback = error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table{
  background: transparent;
  tr{
    td{
      text-align: left;
    }
  }
}
</style>